import userDefaultImage from '../../assets/header/profile.svg';
import DeleteForever from '../../assets/screening/DeleteForever';
import InvitedIcon from '../../assets/workspace/InvitedIcon';
import { PartnerToWorkspaceStatus, PartnerWorkspaceRole } from '../../constants/enums';
import { t } from '../../i18n/i18n';
import './MemberRow.scss';
import TransferOwnership from '../../assets/search/TransferOwnership';
import CopyCheck from '../../assets/search/Check';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const formatNameWithRole = (partner) =>
  partner
    ? `${partner.firstName} ${partner.lastName}${partner.role ? ` (${partner.role})` : ''}`
    : '';

const messageDefinition = (member) => {
  let messageBox = t('ELIMINATE_MEMBER');
  if (member?.role === PartnerWorkspaceRole.OWNER) {
    messageBox = t('OWNER_CANNOT_BE_DELETED');
  }
  return messageBox;
};

const MemberRow = ({
  className = '',
  member,
  handleOpenModal,
  ownerModification,
  isOwner,
  transferProperty,
  approveRequest,
}) => (
  <div
    className={`d-flex align-items-center justify-content-between member-row-container ${className}`}
    data-testid="member-row-container"
  >
    <div className="d-flex align-items-center">
      {member?.partner ? (
        <div
          className="member-row-image rounded-circle"
          data-testid="member-row-partner-image"
          style={{
            backgroundImage: `url('${member.partner.picture}'), url('${userDefaultImage}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      ) : (
        <div className="member-row-image" data-testid="member-row-invited-image">
          <InvitedIcon />
        </div>
      )}
      <div className="d-flex flex-column ml-3">
        <span
          className={`font-montserrat font-weight-500 text-sm member-row-text mb-1 ${
            !member?.partner ? 'member-without-account-text italic' : ''
          }`}
        >
          {member?.partner ? formatNameWithRole(member.partner) : t('MEMBER_ROW_INVITED_TEXT')}
        </span>
        <span className="font-montserrat font-weight-500 text-xs member-row-text">
          {member?.email}
        </span>
      </div>
    </div>
    <div className="d-flex flex-row overflow-auto pr-2">
      <MemberRoleTag role={member?.partner ? member?.role : null} status={member?.status} />
      {isOwner && member?.role === PartnerWorkspaceRole.ADMINISTRATOR && member?.partner && (
        <OverlayTrigger placement="top" overlay={<Tooltip>{t('TRANSFER_OWNERSHIP')}</Tooltip>}>
          <button
            className="pl-3 bg-transparent border-0 font-montserrat font-weight-500 text-sm text-gray-4 text-underline"
            type="button"
            onClick={async () => {
              await transferProperty(member.id);
            }}
          >
            <TransferOwnership />
          </button>
        </OverlayTrigger>
      )}
      {member?.status === PartnerToWorkspaceStatus.WAITING_APPROVAL && (
        <OverlayTrigger placement="top" overlay={<Tooltip>{t('APPROVE_REQUEST')}</Tooltip>}>
          <button
            className="pl-3 bg-transparent border-0 font-montserrat font-weight-500 text-sm text-gray-4 text-underline"
            type="button"
            onClick={async () => {
              await approveRequest(member);
            }}
          >
            <CopyCheck />
          </button>
        </OverlayTrigger>
      )}
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>{() => messageDefinition(member)}</Tooltip>}
      >
        <button
          type="button"
          className="bg-transparent border-0 outline-none delete-workspace-members ml-2 d-flex pt-1 pb-1 justify-content"
          onClick={() => handleOpenModal(member)}
          data-testid="delete-workspace-members-button"
          disabled={member?.role === PartnerWorkspaceRole.OWNER || !ownerModification}
          style={{ border: 'none', background: 'transparent' }}
        >
          <DeleteForever
            fill={
              member?.role === PartnerWorkspaceRole.OWNER || !ownerModification
                ? '#C9C9C9'
                : '#5552F9'
            }
          />
        </button>
      </OverlayTrigger>
    </div>
  </div>
);

const MemberRoleTag = ({
  role,
  status,
  className = 'ml-3 d-flex pt-1 justify-content align-items-center',
}) => {
  const rolesStyles = {
    [PartnerWorkspaceRole.OWNER]: {
      backgroundColor: '#CAE8DB',
      color: '#3D8C6B',
    },
    [PartnerWorkspaceRole.ADMINISTRATOR]: {
      backgroundColor: '#D9D9D9',
      color: '#5F5F5F',
    },
    [PartnerWorkspaceRole.VISUALIZATOR]: {
      backgroundColor: '#D9D9D9',
      color: '#5F5F5F',
    },
    default: {
      backgroundColor: '#FFE2E3',
      color: '#FD6466',
    },
  };

  return (
    <span
      className={`font-montserrat font-weight-700 text-xs rounded-sm py-1 px-2 ${className}`}
      style={rolesStyles[role] ?? rolesStyles.default}
      data-testid="member-role-tag"
    >
      {status === PartnerToWorkspaceStatus.WAITING_ACCEPTANCE ||
      status === PartnerToWorkspaceStatus.WAITING_APPROVAL
        ? t(`MEMBER_ROW_ROLE_TAG_${status}`)
        : role
        ? t(`MEMBER_ROW_ROLE_TAG_${role}`)
        : ''}
    </span>
  );
};

export default MemberRow;
