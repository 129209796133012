import { t } from '../../i18n/i18n';
import './QuestionWrite.scss';
import useCreateScreening from '../../hooks/useCreateScreening';
import UploadTestFiles from '../UploadTestFiles/UploadTestFiles';
import { TestFileType } from '../../constants/enums';
import GenericToggle from '../GenericToggle/GenericToggle';
import InfoTooltip from '../InfoTooltip/InfoTooltip';

const QuestionWrite = ({ testGroupABM }) => {
  const {
    handleAddQuestionWrite,
    currentQuestionData,
    handleAddTestFile,
    handleRemoveTestFile,
    handleUpdateTestFile,
    handleToggleIsTyping,
  } = useCreateScreening();
  return (
    <div data-testid="question-text">
      <textarea
        name="text"
        className={`maya-blue-thin-border outline-none w-100 mt-3 p-2 px-3 text-xs font-montserrat ${
          currentQuestionData?.text?.length ? 'selected-border' : 'unselected-border'
        }`}
        rows="3"
        placeholder={t('CREATE_SCREENING_YOUR_QUESTIONS_WRITE_INPUT_PLACEHOLDER')}
        onChange={(e) => handleAddQuestionWrite(e)}
        value={currentQuestionData?.text ?? ''}
        maxLength={5000}
        data-testid="question-text-textarea"
      />
      {testGroupABM && (
        <textarea
          name="formula"
          className={`maya-blue-thin-border outline-none w-100 mt-3 p-2 px-3 text-xs font-montserrat ${
            currentQuestionData?.formula?.length ? 'selected-border' : 'unselected-border'
          }`}
          rows="2"
          placeholder={t('CREATE_SCREENING_YOUR_FORMULAS_PLACEHOLDER')}
          onChange={(e) => handleAddQuestionWrite(e)}
          value={currentQuestionData?.formula ?? ''}
          maxLength={500}
          data-testid="question-text-textarea"
        />
      )}
      <>
        {testGroupABM && (
          <UploadTestFiles
            fileTypes={[TestFileType.IMAGE, TestFileType.AUDIO]}
            handleAddFile={handleAddTestFile}
            handleRemoveFile={handleRemoveTestFile}
            handleUpdateFile={handleUpdateTestFile}
            testFiles={currentQuestionData?.testFiles ?? []}
            id="test"
          />
        )}
      </>
      <div className="d-flex">
        <span className="font-montserrat">{t('QUESTION_TEXT_TOGGLE_TYPING')}</span>
        <GenericToggle
          actionFunction={() => {
            if (currentQuestionData?.isTyping) {
              handleToggleIsTyping(false);
            } else {
              handleToggleIsTyping(true);
            }
          }}
          defaultActive={currentQuestionData?.isTyping}
        />
      </div>
      <div className="row mt-3 justify-content-start">
        <p
          className="text-sm ml-3 italic font-montserrat mt-1"
          data-testid="question-text-max-char-answer-p"
        >
          {t('ADD_CORRECT_ANSWER')}:
        </p>
        <div
          className={`input-group div-input-write-${
            currentQuestionData?.isTyping ? 'typing' : 'short'
          } maya-blue-thin-border ml-3 b-radius-8 ${
            currentQuestionData?.maxChar?.length ? 'selected-border' : 'unselected-border'
          }`}
        >
          {currentQuestionData?.isTyping ? (
            <div className="w-100">
              <div className="h-100 w-100 mb-2">
                <textarea
                  name="writeResponse"
                  value={currentQuestionData?.writeResponse ?? ''}
                  placeholder={t('ADD_CORRECT_ANSWER_WITH_DETAIL')}
                  onChange={(e) => handleAddQuestionWrite(e)}
                  className="form-control-sm border-0 w-100 h-100 b-radius-8 max-char-input text-xs font-weight-700 font-montserrat"
                  rows={3}
                  maxLength={3000}
                  data-testid="question-write-response-textarea"
                />
              </div>
              <div className="d-flex align-items-center text-xs italic font-montserrat ml-2">
                {t('ADD_WRITE_QUESTION_NOTES')}
                <InfoTooltip
                  placement="top"
                  content={
                    <div className="d-flex flex-column py-2">
                      <p className="text-xs font-montserrat  mb-2">
                        {t('ADD_WRITE_QUESTION_TOOLTIP_CORRECTION_TYPING')}
                      </p>
                    </div>
                  }
                />
              </div>
            </div>
          ) : (
            <div className="w-100">
              <div className="w-100 mb-2">
                <input
                  type="string"
                  name="writeResponse"
                  value={currentQuestionData?.writeResponse ?? ''}
                  placeholder={t('ADD_CORRECT_ANSWER_WITH_DETAIL_SEMICOLON')}
                  onChange={(e) => handleAddQuestionWrite(e)}
                  className="form-control-sm border-0 w-100 h-100 b-radius-8 max-char-input text-xs font-weight-700 font-montserrat"
                  min={0}
                  max={100}
                  data-testid="question-write-response-input"
                />
              </div>
              <div className="d-flex align-items-center text-xs italic font-montserrat ml-2">
                {t('ADD_WRITE_QUESTION_NOTES')}
                <InfoTooltip
                  placement="top"
                  content={
                    <div className="d-flex flex-column py-2">
                      <p className="text-xs font-montserrat  mb-2">
                        {t('ADD_WRITE_QUESTION_TOOLTIP_CORRECTION')}
                      </p>
                    </div>
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionWrite;
